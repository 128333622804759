import React, { useState, useEffect } from "react";
import axios from "axios";

const LicensesPanel = () => {
  const [keys, setKeys] = useState([]); // Состояние для ключей
  const [loading, setLoading] = useState(true); // Состояние загрузки
  const [error, setError] = useState(null); // Состояние для ошибок

  // Загружаем все ключи
  useEffect(() => {
    const fetchKeys = async () => {
      try {
        const apiKey = process.env.REACT_APP_ADMIN_API_KEY;
        const response = await axios.get(
          "https://cheat-lab.store/keys/keys", // Получаем все ключи
          {
            headers: {
              Authorization: `${apiKey}`, // Добавляем заголовок с API ключом
            },
          }
        );
        setKeys(response.data.keys); // Сохраняем ключи в состояние
      } catch (err) {
        setError("Ошибка при получении ключей");
      } finally {
        setLoading(false);
      }
    };

    fetchKeys();
  }, []);

  // Функция для удаления ключа
  const handleDeleteKey = async (keyId) => {
    try {
      const apiKey = process.env.REACT_APP_ADMIN_API_KEY;
      await axios.delete(`https://cheat-lab.store/keys/delete-key/${keyId}`, {
        headers: {
          Authorization: `${apiKey}`,
        },
      });

      // Обновляем список ключей после удаления
      setKeys(keys.filter((key) => key._id !== keyId));
      alert("Ключ успешно удален!");
    } catch (err) {
      setError("Ошибка при удалении ключа");
    }
  };

  // Функция для форматирования времени
  const formatTime = (expiryDate) => {
    if (!expiryDate) return "Не установлено";

    const remainingTime = new Date(expiryDate) - new Date();
    if (remainingTime <= 0) return "Время истекло";

    const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${days} дней ${hours} часов ${minutes} минут`;
  };

  // Функция для отображения продолжительности в днях или часах
  const formatDuration = (duration) => {
    if (!duration) return "Не установлено";

    const hours = duration % 24;
    const days = Math.floor(duration / 24);

    if (days > 0) {
      return `${days} дней ${hours} часов`;
    }
    return `${hours} часов`;
  };

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!keys || keys.length === 0) {
    return <div>Нет доступных лицензий</div>;
  }

  return (
    <div className="licenses-panel">
      <h1>Все ключи</h1>

      <table>
        <thead>
          <tr>
            <th>Игра</th>
            <th>Ключ</th>
            <th>Дата активации</th>
            <th>Дата окончания</th>
            <th>Оставшееся время</th>
            <th>Тег</th>
            <th>Продолжительность</th>
            <th>Статус</th>
            <th>Удалить</th>
          </tr>
        </thead>
        <tbody>
          {keys.map((license) => (
            <tr key={license._id}>
              <td>{license.gameId?.name || "Не указано"}</td>
              <td>{license.key || "Не указан"}</td>
              <td>
                {license.activatedAt
                  ? new Date(license.activatedAt).toLocaleString()
                  : "Не установлено"}
              </td>
              <td>
                {license.expiryDate
                  ? new Date(license.expiryDate).toLocaleString()
                  : "Не установлено"}
              </td>
              <td>{formatTime(license.expiryDate)}</td>
              <td>{license.tag || "Нет тега"}</td>
              <td>{formatDuration(license.duration)}</td>{" "}
              {/* Используем функцию для форматирования продолжительности */}
              <td>{license.status || "Не активен"}</td>
              <td>
                <button onClick={() => handleDeleteKey(license._id)}>
                  Удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LicensesPanel;
