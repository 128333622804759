import React, { useState, useEffect } from "react";
import axios from "axios";

const FreezeKeys = () => {
  const [games, setGames] = useState([]); // Состояние для списка игр
  const [selectedGame, setSelectedGame] = useState(""); // Состояние для выбранной игры
  const [key, setKey] = useState(""); // Состояние для ключа
  const [loading, setLoading] = useState(true); // Состояние для загрузки
  const [error, setError] = useState(null); // Состояние для ошибок
  const [successMessage, setSuccessMessage] = useState(""); // Сообщения об успехе

  // Загружаем список всех игр
  useEffect(() => {
    const fetchGames = async () => {
      try {
        const apiKey = process.env.REACT_APP_ADMIN_API_KEY;
        const response = await axios.get(
          "https://cheat-lab.store/games/all-games",
          {
            headers: {
              Authorization: `${apiKey}`,
            },
          }
        );
        setGames(response.data); // Предполагаем, что сервер возвращает массив игр
      } catch (err) {
        setError("Ошибка при загрузке игр");
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  // Функция для заморозки ключей по выбранной игре
  const handleFreezeKeys = async () => {
    if (!selectedGame) {
      alert("Пожалуйста, выберите игру!");
      return;
    }

    try {
      const apiKey = process.env.REACT_APP_ADMIN_API_KEY;
      await axios.post(
        "https://cheat-lab.store/keys/freeze-keys",
        { gameName: selectedGame },
        {
          headers: {
            Authorization: `${apiKey}`,
          },
        }
      );

      setSuccessMessage(
        `Все ключи для игры "${selectedGame}" были заморожены.`
      );
    } catch (err) {
      setError("Ошибка при заморозке ключей");
    }
  };

  // Функция для разморозки ключей по выбранной игре
  const handleUnfreezeKeys = async () => {
    if (!selectedGame) {
      alert("Пожалуйста, выберите игру!");
      return;
    }

    try {
      const apiKey = process.env.REACT_APP_ADMIN_API_KEY;
      await axios.post(
        "https://cheat-lab.store/keys/unfreeze-keys",
        { gameName: selectedGame },
        {
          headers: {
            Authorization: `${apiKey}`,
          },
        }
      );

      setSuccessMessage(
        `Все замороженные ключи для игры "${selectedGame}" были разморожены.`
      );
    } catch (err) {
      setError("Ошибка при разморозке ключей");
    }
  };

  // Функция для заморозки конкретного ключа
  const handleFreezeSpecificKey = async () => {
    if (!key) {
      alert("Пожалуйста, укажите ключ для заморозки!");
      return;
    }

    try {
      const apiKey = process.env.REACT_APP_ADMIN_API_KEY;
      await axios.post(
        "https://cheat-lab.store/keys/freeze-key",
        { key },
        {
          headers: {
            Authorization: `${apiKey}`,
          },
        }
      );

      setSuccessMessage(`Ключ "${key}" был заморожен.`);
    } catch (err) {
      setError("Ошибка при заморозке ключа");
    }
  };

  // Функция для разморозки конкретного ключа
  const handleUnfreezeSpecificKey = async () => {
    if (!key) {
      alert("Пожалуйста, укажите ключ для разморозки!");
      return;
    }

    try {
      const apiKey = process.env.REACT_APP_ADMIN_API_KEY;
      await axios.post(
        "https://cheat-lab.store/keys/unfreeze-key",
        { key },
        {
          headers: {
            Authorization: `${apiKey}`,
          },
        }
      );

      setSuccessMessage(`Ключ "${key}" был разморожен.`);
    } catch (err) {
      setError("Ошибка при разморозке ключа");
    }
  };

  if (loading) {
    return <div>Загрузка игр...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="freeze-keys-panel">
      <h2>Управление ключами по игре</h2>

      <div>
        <select
          value={selectedGame}
          onChange={(e) => setSelectedGame(e.target.value)}
        >
          <option value="">Выберите игру</option>
          {games.map((game) => (
            <option key={game._id} value={game.name}>
              {game.name}
            </option>
          ))}
        </select>
        <button onClick={handleFreezeKeys}>Заморозить ключи по игре</button>
        <button onClick={handleUnfreezeKeys}>Разморозить ключи по игре</button>
      </div>

      <hr />

      <div>
        <h3>Управление конкретным ключом</h3>
        <input
          type="text"
          placeholder="Введите ключ"
          value={key}
          onChange={(e) => setKey(e.target.value)}
        />
        <button onClick={handleFreezeSpecificKey}>Заморозить ключ</button>
        <button onClick={handleUnfreezeSpecificKey}>Разморозить ключ</button>
      </div>

      {/* Вывод сообщения об успехе */}
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}
    </div>
  );
};

export default FreezeKeys;
