import React, { useState, useEffect } from "react";
import axios from "axios";
import "./GamesList.css"; // Importing custom styles

const GamesList = () => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [newGameNames, setNewGameNames] = useState({}); // Объект для хранения новых названий для каждой игры
  const [newGameStatus, setNewGameStatus] = useState({}); // Объект для хранения новых статусов для каждой игры

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const apiKey = process.env.REACT_APP_ADMIN_API_KEY;

        const response = await axios.get(
          "https://cheat-lab.store/games/all-games",
          {
            headers: {
              Authorization: apiKey,
            },
          }
        );

        setGames(response.data);
      } catch (err) {
        setError("Ошибка при получении списка игр");
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleNameChange = (e, gameId) => {
    setNewGameNames({
      ...newGameNames,
      [gameId]: e.target.value, // Обновление названия для конкретной игры
    });
  };

  const handleStatusChange = (e, gameId) => {
    setNewGameStatus({
      ...newGameStatus,
      [gameId]: e.target.value, // Обновление статуса для конкретной игры
    });
  };

  const handleUpdateGame = async (gameId) => {
    if (!selectedFile && !newGameNames[gameId] && !newGameStatus[gameId]) {
      alert(
        "Выберите файл, введите новое название игры или выберите статус перед обновлением"
      );
      return;
    }

    const formData = new FormData();
    if (selectedFile) {
      formData.append("file", selectedFile);
    }
    if (newGameNames[gameId]) {
      formData.append("name", newGameNames[gameId]); // Добавление нового названия для конкретной игры
    }
    if (newGameStatus[gameId]) {
      formData.append("status", newGameStatus[gameId]); // Добавление нового статуса для конкретной игры
    }

    try {
      const apiKey = process.env.REACT_APP_ADMIN_API_KEY;

      const response = await axios.put(
        `https://cheat-lab.store/games/edit-game/${gameId}`,
        formData,
        {
          headers: {
            Authorization: apiKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setSuccessMessage(response.data.message);
      setSelectedFile(null);
      setNewGameNames({}); // Сбросить все новые названия после успешного обновления
      setNewGameStatus({}); // Сбросить статус
    } catch (err) {
      setError(err.response?.data?.error || "Ошибка при обновлении игры");
    }
  };

  // Функция для удаления игры
  const handleDeleteGame = async (gameId) => {
    const confirmed = window.confirm(
      "Вы уверены, что хотите удалить эту игру?"
    );
    if (!confirmed) return;

    try {
      const apiKey = process.env.REACT_APP_ADMIN_API_KEY;

      const response = await axios.delete(
        `https://cheat-lab.store/games/delete-game/${gameId}`,
        {
          headers: {
            Authorization: apiKey,
          },
        }
      );

      setSuccessMessage(response.data.message);

      // Обновляем список игр после удаления
      setGames((prevGames) => prevGames.filter((game) => game._id !== gameId));
    } catch (err) {
      setError(err.response?.data?.error || "Ошибка при удалении игры");
    }
  };

  if (loading) {
    return <div className="loading">Загрузка...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="games-list">
      <h1>Список игр</h1>
      {successMessage && <p className="success">{successMessage}</p>}
      {games.length === 0 ? (
        <p>Нет доступных игр.</p>
      ) : (
        <ul>
          {games.map((game) => (
            <li key={game._id}>
              <div>
                <strong>{game.name}</strong>
                <p>
                  <span>id: {game._id}</span>
                </p>
                <p>fileName: {game.fileName}</p>
                <p>Status: {game.status}</p>
              </div>
              <div>
                <input type="file" onChange={handleFileChange} />
                <input
                  type="text"
                  placeholder="Новое название"
                  value={newGameNames[game._id] || ""} // Привязка значения к конкретной игре
                  onChange={(e) => handleNameChange(e, game._id)} // Обработчик изменения для каждой игры
                />
                <select
                  value={newGameStatus[game._id] || game.status} // Привязка значения статуса
                  onChange={(e) => handleStatusChange(e, game._id)} // Обработчик изменения для статуса
                >
                  <option value="Undetected">Undetected</option>
                  <option value="On update">On update</option>
                  <option value="Use at own risk">Use at own risk</option>
                </select>
                <button onClick={() => handleUpdateGame(game._id)}>
                  Изменить игру
                </button>
                <button
                  onClick={() => handleDeleteGame(game._id)}
                  className="delete-btn"
                >
                  Удалить игру
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default GamesList;
