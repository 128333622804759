import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CreateKeyForm.css"; // Импортируем стили

const CreateKeyForm = () => {
  const [gameId, setGameId] = useState("");
  const [games, setGames] = useState([]);
  const [tag, setTag] = useState("");
  const [expiryTime, setExpiryTime] = useState(""); // Общее состояние для срока действия
  const [expiryType, setExpiryType] = useState("hours"); // Тип срока действия (часы или дни)
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    // Получение списка игр с сервера
    const fetchGames = async () => {
      try {
        const response = await axios.get(
          "https://cheat-lab.store/games/all-games"
        );
        setGames(response.data);
      } catch (err) {
        console.error("Ошибка при получении списка игр", err);
      }
    };

    fetchGames();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Конвертация времени в часы, если выбран тип "дни"
    let expiryInHours = expiryTime;
    if (expiryType === "days") {
      expiryInHours = expiryTime * 24; // Переводим дни в часы
    }

    try {
      const apiKey = process.env.REACT_APP_ADMIN_API_KEY; // Получаем API ключ из переменной окружения

      const response = await axios.post(
        "https://cheat-lab.store/keys/create",
        {
          gameId,
          tag,
          expiryHours: Number(expiryInHours), // Отправляем количество часов
        },
        {
          headers: {
            Authorization: apiKey, // Передаем ключ в заголовке Authorization
          },
        }
      );

      setMessage(response.data.message);
      setError(null);
    } catch (err) {
      setError(err.response?.data?.error || "Ошибка при создании ключа");
      setMessage("");
    }
  };

  return (
    <div className="create-key-form">
      <h2>Создать новый ключ</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Игра:</label>
          <select
            value={gameId}
            onChange={(e) => setGameId(e.target.value)}
            required
          >
            <option value="">Выберите игру</option>
            {games.map((game) => (
              <option key={game._id} value={game._id}>
                {game.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Тег:</label>
          <input
            type="text"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
          />
        </div>
        <div>
          <label>Срок действия:</label>
          <input
            type="number"
            value={expiryTime}
            onChange={(e) => setExpiryTime(e.target.value)}
            required
          />
          <select
            value={expiryType}
            onChange={(e) => setExpiryType(e.target.value)}
            required
          >
            <option value="hours">Часы</option>
            <option value="days">Дни</option>
          </select>
        </div>
        <button type="submit">Создать ключ</button>
      </form>
      {message && <p className="success">{message}</p>}
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default CreateKeyForm;
