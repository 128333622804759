import React, { useState, useEffect } from "react";
import axios from "axios";

const UsersPanel = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Функция для получения всех пользователей
    const fetchUsers = async () => {
      try {
        const apiKey = process.env.REACT_APP_ADMIN_API_KEY; // Получаем API ключ из переменной окружения
        const response = await axios.get(
          "https://cheat-lab.store/users/all-users",
          {
            headers: {
              Authorization: apiKey, // Передаем ключ в заголовке Authorization
            },
          }
        );
        setUsers(response.data.users); // Сохраняем пользователей в состояние
      } catch (err) {
        setError("Ошибка при получении пользователей");
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Функция для сброса HWID
  const resetHwid = async (username) => {
    try {
      const apiKey = process.env.REACT_APP_ADMIN_API_KEY; // Получаем API ключ
      const response = await axios.post(
        "https://cheat-lab.store/users/reset-hwid", // Адрес вашего нового маршрута
        { username },
        {
          headers: {
            Authorization: apiKey,
          },
        }
      );
      // Обновляем состояние после успешного сброса HWID
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.username === username ? { ...user, hwid: null } : user
        )
      );
      alert(response.data.message); // Показываем сообщение об успехе
    } catch (err) {
      alert(err.response ? err.response.data.error : "Ошибка при сбросе HWID");
    }
  };

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="users-panel">
      <h1>Все пользователи</h1>
      {users.length === 0 ? (
        <p>Нет пользователей в системе.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Имя пользователя</th>
              <th>HWID</th>
              <th>Действия</th> {/* Добавили колонку для кнопки */}
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user._id}>
                <td>{user.username}</td>
                <td>{user.hwid ? user.hwid : "Нет HWID"}</td>
                <td>
                  {user.hwid && (
                    <button onClick={() => resetHwid(user.username)}>
                      Сбросить HWID
                    </button>
                  )}
                </td>{" "}
                {/* Добавили кнопку для сброса HWID */}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default UsersPanel;
