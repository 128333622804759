import AllLicenses from "./components/AllLicenses";
import UsersPanel from "./components/UsersPanel";
import CreateKeyForm from "./components/CreateKeyForm";
import GamesList from "./components/GameList";
import CreateGameForm from "./components/CreateGameForm";
import FreezeKeys from "./components/FreezeKeys";
function App() {
  return (
    <div className="App">
      <FreezeKeys />
      <AllLicenses />
      <UsersPanel />
      <CreateKeyForm />
      <CreateGameForm />
      <GamesList />
    </div>
  );
}

export default App;
