import React, { useState } from "react";
import axios from "axios";
import "./CreateGameForm.css"; // Импортируем стили

const CreateGameForm = () => {
  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", file);

    try {
      const apiKey = process.env.REACT_APP_ADMIN_API_KEY; // Получаем API ключ из переменной окружения

      const response = await axios.post(
        "https://cheat-lab.store/games/create",
        formData,
        {
          headers: {
            Authorization: apiKey, // Передаем ключ в заголовке Authorization
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setMessage(response.data.message);
      setError(null);
      setName("");
      setFile(null);
    } catch (err) {
      setError(err.response?.data?.error || "Ошибка при создании игры");
      setMessage("");
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="create-game-form">
      <h2>Создать новую игру</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Название игры:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Загрузить файл:</label>
          <input type="file" onChange={handleFileChange} required />
        </div>
        <button type="submit">Создать игру</button>
      </form>
      {message && <p className="success">{message}</p>}
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default CreateGameForm;
